<template>
  <v-card>
    <v-card-title>
      <template>{{ franchisee.name }}</template>
      <span class="font-weight-bold mx-1">Status:</span>
      <!-- <reseller-status v-if="client.is_reseller" :client="client"/>
      <end-user-status v-else :client="client"/> -->
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="tab">
        <v-tab>
          Detalii Cont
        </v-tab>
        <v-tab v-if="$store.getters['auth/currentUser'].franchisee_id">
          Branding
        </v-tab>
        <v-tab v-if="$store.getters['auth/currentUser'].franchisee_id">
          Admin Branding
        </v-tab>
        <v-tab v-if="$store.getters['auth/currentUser'].franchisee_id">
          Email Settings
        </v-tab>
        <v-tab v-if="$store.getters['auth/currentUser'].franchisee_id">
          <!-- Social media login settings -->
          Other Settings
        </v-tab>
        <!-- <v-tab>
          Agenti
        </v-tab> -->
      </v-tabs>
      <div class="p-5">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <account-details-editor :franchisee="franchisee" />
          </v-tab-item>
          <v-tab-item v-if="$store.getters['auth/currentUser'].franchisee_id">
            <branding :franchiseeId="$route.params.id" />
          </v-tab-item>
          <v-tab-item v-if="$store.getters['auth/currentUser'].franchisee_id">
            <admin-branding :franchiseeId="$route.params.id" />
          </v-tab-item>
          <v-tab-item v-if="$store.getters['auth/currentUser'].franchisee_id">
            <email-settings :franchiseeId="$route.params.id" />
          </v-tab-item>
          <v-tab-item v-if="$store.getters['auth/currentUser'].franchisee_id">
            <social-media-login-settings :franchiseeId="$route.params.id" />
          </v-tab-item>
          <!-- <v-tab-item>
            <agents :franchiseeId="$route.params.id" />
          </v-tab-item> -->
        </v-tabs-items>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import AccountDetailsEditor from '@/views/SingleFranchisee/components/AccountDetailsEditor'
import Branding from '@/views/SingleFranchisee/components/Branding/Branding'
import AdminBranding from '@/views/SingleFranchisee/components/Branding/AdminBranding'
import EmailSettings from '@/views/SingleFranchisee/components/EmailSettings'
import SocialMediaLoginSettings from '@/views/SingleFranchisee/components/SocialMediaLoginSettings'
import Agents from '@/views/SingleFranchisee/components/Agents/List'

export default {
  name: 'View',
  components: {
    AccountDetailsEditor,
    Branding,
    AdminBranding,
    Agents,
    EmailSettings,
    SocialMediaLoginSettings
  },
  data() {
    return {
      tab: ''
    }
  },
  computed: {
    currentFranchiseeID() {
      return this.$route.params.id
    },
    franchisee() {
      return this.$store.getters['singleFranchisee/item']
    }
  },
  methods: {
    loadFranchisee() {
      this.$store.dispatch('singleFranchisee/load', this.currentFranchiseeID)
    },
    loadTab() {
      if (window.location.href.includes('branding')) {
        this.tab = 1
      }
      else if (window.location.href.includes('adminBranding')) {
        this.tab = 2
      }
      else if (window.location.href.includes('adminBranding')) {
        this.tab = 2
      }
      else if (window.location.href.includes('mailgun')) {
        this.tab = 3
      }else{
        this.tab = 0
      }
    }
  },
  created() {
    this.loadFranchisee()
    this.loadTab()
  }
}
</script>
<style lang="scss" scoped></style>
