<template>
  <v-sheet>
    <template slot="progress">

      <loader :loading="loading" colors="rgba(255, 255, 255, 0.90)" loader-color="#f0483d" size="fa-6x"></loader>

    </template>
    <v-card class="p-2" flat>
      <v-row class="mb-4 mt-5">

        <v-col cols="6">

          <h4 class=" mb-4 ">Google</h4>
          <div class="mb-4">
            <v-text-field v-model="socialMediaLoginSettingsData.google.client_id" label="Client id" />
            <error-messages :errors="errors && errors.googleClient_id" />
          </div>
          <v-text-field v-model="socialMediaLoginSettingsData.google.client_secret" label="Client secret" />
          <error-messages :errors="errors.googleClient_secret" />
        </v-col>

        <v-col cols="6">
          <h4 class=" mb-4 ">Facebook</h4>
          <div class="mb-4">
            <v-text-field v-model="socialMediaLoginSettingsData.facebook.client_id" label="Client id" />
            <error-messages :errors="errors.facebookClient_id" />
          </div>

          <v-text-field v-model="socialMediaLoginSettingsData.facebook.client_secret" label="Client secret" />
          <error-messages :errors="errors.facebookClient_secret" />
        </v-col>

        <v-col cols="6">
          <h4 class=" mb-4 ">Tiny MCE API Key</h4>
          <div class="mb-4">
            <v-text-field
              v-model="socialMediaLoginSettingsData.tinyMce.tinyMceApiKey"
              label="Tiny MCE API Key" />
            <error-messages :errors=" errors.tinyMceApiKey " />
          </div>

        </v-col>

      </v-row>
      <v-divider />
      <v-row class="mt-4">
        <v-col>
          <v-btn :disabled=" loadingSave " :loading=" loadingSave " color="success" class="primary-button"
            @click=" updateEmailServerSettings ">Salveaza</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-sheet>
</template>

<script>
// import axios from 'axios'
import axios from '@/axios'
import ErrorMessages from '@/components/general-form/ErrorMessages'
let baseURL = process.env.VUE_APP_BASE_URL_API
let baseURLAPP = process.env.VUE_APP_BASE_URL_API

baseURL = `${baseURL}/app`
export default {
  name: 'SocialMediaLoginSettings',
  components: { ErrorMessages },
  data() {
    return {
      errors: {
        googleClient_id: '',
        googleClient_secret: '',
        facebookClient_id: '',
        facebookClient_secret: '',
        tinyMceApiKey: ''
      },
      socialMediaLoginSettingsData: {
        google: {
          client_id: '',
          client_secret: ''
        },
        facebook: {
          client_id: '',
          client_secret: ''
        },
        tinyMce: {
          tinyMceApiKey: '',
        },
      },
      loading: false,
      loadingSave: false,
    }
  },
  props: {
    franchiseeId: {
      required: true
    }
  },
  methods: {
    loadItems(isLoading) {
      if (isLoading)
        this.loading = true
      axios
        .get(baseURL + `/clients/franchisees/${this.franchiseeId}/socialLoginDetails`)
        .then(response => {
          if (response.data && response.data.length > 0) {
            this.socialMediaLoginSettingsData = {
              google: {
                client_id: response.data[0].client_id,
                client_secret: response.data[0].client_secret
              },
              facebook: {
                client_id: response.data[1].client_id,
                client_secret: response.data[1].client_secret
              },
              tinyMce: {
                tinyMceApiKey: response.data[2].tinyMceApiKey,
              },
            }
          }
          this.loading = false
        })
    },
    updateEmailServerSettings() {
      const socialMediaLoginSettingsData = [
        {
          type: "google",
          client_id: this.socialMediaLoginSettingsData.google.client_id,
          client_secret: this.socialMediaLoginSettingsData.google.client_secret
        },
        {
          type: "facebook",
          client_id: this.socialMediaLoginSettingsData.facebook.client_id,
          client_secret: this.socialMediaLoginSettingsData.facebook.client_secret
        },
        {
          type: "tinyMce",
          tinyMceApiKey: this.socialMediaLoginSettingsData.tinyMce.tinyMceApiKey,
        }
      ]
      this.loadingSave = true
      this.$http.post(`clients/franchisees/${this.franchiseeId}/socialLoginDetails`, socialMediaLoginSettingsData)
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Updated other  setting!',
            color: 'success'
          })
          this.loadItems(false)
        })
        .catch((err) => {
          if (err.response.status === 422) {
            console.log(err.response.data.errors)
            // errors: {
            //   "0.client_id": [
            //     ""
            //   ],
            //     "1.client_id": [
            //       ""
            //     ],
            //       "0.client_secret": [
            //         ""
            //       ],
            //         "1.client_secret": [
            //           ""
            //         ]
            // },
            const googleClient_id = err.response.data.errors && err.response.data.errors['0.client_id']
            const googleClient_secret = err.response.data.errors && err.response.data.errors['0.client_secret']
            const facebookClient_id = err.response.data.errors && err.response.data.errors['1.client_id']
            const facebookClient_secret = err.response.data.errors && err.response.data.errors['1.client_secret']
            const tinyMceApiKey = err.response.data.errors && err.response.data.errors['2.tinyMceApiKey']

            this.errors = {
              googleClient_id: googleClient_id,
              googleClient_secret: googleClient_secret,
              facebookClient_id: facebookClient_id,
              facebookClient_secret:facebookClient_secret,
              tinyMceApiKey: tinyMceApiKey
            }
            this.$vs.notify({
              title: 'Eroare!',
              text: 'Nu toate campurile obligatorii au fost completate.',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'A aparut o eroare. Va rugam reincarcati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
        })
        .finally(() => {
          this.loadingSave = false
        })
    }
  },
  created() {
    this.loadItems(true)
  }

}
</script>